.top-list li{
padding: 30px;
}
.bg-shop-img{
    background-image: url('../assets/bg-shop.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
}
.top-list li p{
    padding-top: 10px;
}
.top-list li img{
    box-shadow: 1px 2px 2px rgb(194, 189, 189);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 10px;
}

.cardd-link{
    color :black;
    text-decoration: none;
}