/* #app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
} */



.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
 
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  margin: 10px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.about-company{
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
}

.abt-compny-child{
  padding:0 10px;
}
.child-span{
  color: grey;
}