.profile-nav-list{
    margin-top: 5px;
    align-items: center;
   
}
.profile-nav-list a{
    text-decoration: none;
    color: black;
    margin: 10px 0 10px 10px;
    font-size:16px;
    font-weight: bold;
}
.webPgCard{
    width: 300px;
    background-color: rgb(72, 163, 238);
    color: white;
    text-align: center;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, .137);
    transition: all .4s ease 0s;
    transform: scale(1.1);
    opacity: 0.98s;
    overflow: hidden;
    margin: 25px;
    border-radius: 4px;
}
.webPgCard p{
    background-color: white;
    color:rgb(34, 34, 164) ;
    font-weight: 600;
    margin: 0;
    padding: 6px 0;
}
.qualityPgCard{
    width: 250px;
    background-color: rgb(217, 255, 145);
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 5px 30px;
    margin: 25px;
    border-radius: 1px;
}